import gql from 'graphql-tag';

export const REGISTER_MUTATION = gql`
  mutation register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        username
        email
        confirmed
      }
    }
  }
`;
