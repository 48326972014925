//spacing
export const globalStyles = {
  spacing: {
    base: 8,
    xs: 16,
    s: 24,
    m: 32,
    l: 40,
    xl: 48,
    xxl: 56,
    xxxl: 64,
    xxxxl: 72,
    xxxxxl: 80,
  },
  fontSize: {
    xs: 12,
    s: 14,
    m: 16,
    l: 20,
    xl: 24,
    xxl: 38,
    xxxl: 46,
    xxxxl: 56,
  },
  colors: {
    primary: '#1890ff',
    accent: '#ffa940',
    secondaryLight: '#7faef5',
    secondaryDark: '#10239e',
    neutral: '#fafafa',
    stroke: '#d9d9d9',
    primaryText: '#262626',
    secondaryText: '#595959',
    secondary: '#d6e4ff',
    secondaryGrey: '#f0f0f0',
    white: '#fff',
    warning: '#ff6870',
    green: '#219653',
    red: '#ff0000',
    blue: '#3e87fa',
    lightBlue: '#91BDFF',
    midGray: '#bfbfbf',
    orange: '#ED7858',
    darkOrange: '#fa8c16',
    yellow: '#f2c94c',
  },
  borderRadius: {
    basic: 10,
    xl: 40,
  },
  minScreenWidth: {
    xs: '(max-width: 575px)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1600px)',
  },
  maxScreenWidth: {
    md: '(max-width: 768px)',
    lg: '(max-width: 992px)',
    xl: '(max-width: 1200px)',
  },
};
