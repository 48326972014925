import React from 'react';
import { Typography, Layout, Row, Col } from 'antd';
import { globalStyles } from '../styles/variables';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import SignUpForm from '../components/SignUpForm/SignUpForm';
import { BackgroundImage } from '../components/BackgroundImage/BackgroundImage';
import './sign-up.less';
import { Logotype } from '../components/Navbar/components/Logotype';

const SignUp = () => {
  const { Title } = Typography;

  const { isDesktop, isTablet, matches } = useMediaQuery(
    globalStyles.minScreenWidth.lg
  );

  return (
    <Layout className="sign-in-page">
      <Row className="sign-in-logotype">
        <Logotype />
      </Row>
      <Row align={isTablet ? 'middle' : 'top'} className="page-container">
        <Col
          span={matches ? 10 : 20}
          offset={isDesktop ? 4 : 2}
          className="sign-up-page-col"
        >
          <Title level={3} className="sign-up-page-heading">
            Cześć, zaczynamy naukę?
          </Title>
          <Row className="sign-up-page-form-wrapper">
            <SignUpForm />
          </Row>
        </Col>
      </Row>
      <BackgroundImage />
    </Layout>
  );
};

export default SignUp;
