import React from 'react';
import '../Navbar.less';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export const Logotype: React.FC = () => {
  return (
    <Link to="/">
      <StaticImage
        src={'../../../images/logo.svg'}
        className="logotype"
        alt="Wos logo"
      />
      <StaticImage
        src={'../../../images/logotext.svg'}
        className="logotext"
        alt="społeczeństwo wiedzy"
      />
    </Link>
  );
};
