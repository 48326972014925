import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './BackgroundImage.less';

export const BackgroundImage = () => {
  return (
    <StaticImage
      alt={'Background image'}
      className="background-image"
      objectFit={'cover'}
      src={'./../../images/BannerHomePage.png'}
    />
  );
};
