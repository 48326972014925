export const ROUTES = Object.freeze({
  HOME: '/',
  SIGN_UP: '/zarejestruj-sie',
  SIGN_IN: '/zaloguj-sie',
  FORGOT_PASSWORD: '/przypomnij-haslo',
  NEW_PASSWORD: '/nowe-haslo',
  PRODUCTS: '/produkty',
  ORDER: '/zamowienie',
  ABOUT: '/o-nas',
  LESSON: '/lekcja',
  PRIVACY_POLICY: '/polityka-prywatnosci',
  COURSE: '/kursy',
  COLLECTIONS: '/zbiory-zadan',
  CONTACT: '/kontakt',
  SHOPPING_CART: '/koszyk',
  PROFILE: '/profil',
  //TO DO
  ESSAYS: '/wypracowania',
  TERMS_AND_CONDITIONS: '/regulamin',
  APP: '/app',
  SETTINGS: '/app/settings',
});
