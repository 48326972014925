import { useState, useEffect } from 'react';

export const MEDIA_RESOLUTION = Object.freeze({
  isMobile: '(max-width: 575px)',
  isTablet: '(min-width: 768px)',
  isLaptop: '(min-width: 1024px)',
  isDesktop: '(min-width: 1200px)',
});

const useMediaQuery = (query?: string) => {
  const [matches, setMatches] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const mobileMedia = window.matchMedia(MEDIA_RESOLUTION.isMobile);
    const tabletMedia = window.matchMedia(MEDIA_RESOLUTION.isTablet);
    const laptopMedia = window.matchMedia(MEDIA_RESOLUTION.isLaptop);
    const desktopMedia = window.matchMedia(MEDIA_RESOLUTION.isDesktop);

    if (mobileMedia.matches !== isMobile) {
      setIsMobile(mobileMedia.matches);
    }

    if (tabletMedia.matches !== isTablet) {
      setIsTablet(tabletMedia.matches);
    }

    if (laptopMedia.matches !== isLaptop) {
      setIsLaptop(mobileMedia.matches);
    }

    if (desktopMedia.matches !== isDesktop) {
      setIsDesktop(desktopMedia.matches);
    }

    const media = query && window.matchMedia(query);

    if (media && media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setIsMobile(mobileMedia.matches);
      setIsTablet(tabletMedia.matches);
      setIsLaptop(laptopMedia.matches);
      setIsDesktop(desktopMedia.matches);
      media && setMatches(media.matches);
    };

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [isMobile, isTablet, isLaptop, isDesktop]);

  return { isMobile, isTablet, isLaptop, isDesktop, matches };
};

export default useMediaQuery;
