import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Button, Checkbox, Form, Input, Typography, Modal } from 'antd';
import './SignUpForm.less';
import { FormInput } from '../FormInput/FormInput';
import { useSignup, UseSignupProps } from '../../utils/hooks/useSignup';
import { ROUTES } from '../../constants/routes';

const SignUpForm: React.FC = () => {
  const { Paragraph, Text } = Typography;

  const [form] = Form.useForm();

  const [usernameError, setUserdameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmError, setConfirmError] = useState<string>('');
  const [consentError, setConsentError] = useState<string>('');

  const { handleSignup, loading, error } = useSignup();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    window.open('https://google.com', '_blank', 'noopener,noreferrer');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleErrorMessage = (message: string) => {
    if (message === 'Username already taken') {
      setUserdameError('Ta nazwa uytkownika jest juz zajęta');
    }
    if (message === 'Email is already taken') {
      setEmailError('Ten Email jest juz zajęty');
    }
  };

  const resetErrorMessage = (fieldType: string) => {
    if (fieldType === 'username') setUserdameError('');
    if (fieldType === 'email') setEmailError('');
    if (fieldType === 'password') setPasswordError('');
    if (fieldType === 'confirm' || fieldType === 'password')
      setConfirmError('');
    if (fieldType === 'consent') setConsentError('');
  };

  const checkForErrors = (fieldType: string) => {
    resetErrorMessage(fieldType);

    type FieldError = { errors: string[]; name: string[] };
    form
      .validateFields()
      .then()
      .catch(errorInfo => {
        errorInfo?.errorFields.forEach((error: FieldError) => {
          const message = error.errors[0];
          const type = error.name[0];

          if (
            (type === 'username' && fieldType === 'username') ||
            (type === 'username' && fieldType === 'all')
          )
            setUserdameError(message);
          if (
            (type === 'email' && fieldType === 'email') ||
            (type === 'email' && fieldType === 'all')
          )
            setEmailError(message);
          if (
            (type === 'password' && fieldType === 'password') ||
            (type === 'password' && fieldType === 'all')
          )
            setPasswordError(message);
          if (
            (type === 'confirm' && fieldType === 'confirm') ||
            (type === 'confirm' && fieldType === 'password') ||
            (type === 'confirm' && fieldType === 'all')
          )
            setConfirmError(message);
          if (
            (type === 'consent' && fieldType === 'consent') ||
            (type === 'consent' && fieldType === 'all')
          )
            setConsentError(message);
        });
      });
  };
  const onFinish = async (inputs: UseSignupProps) => {
    const { data, errors } = await handleSignup(inputs);
    // @ts-ignore
    const message = errors?.graphQLErrors[0].message;
    handleErrorMessage(message);

    if (data) {
      showModal();
    }
  };
  const onFinishFailed = () => {
    checkForErrors('all');
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        onValuesChange={(changedValue: object) => {
          checkForErrors(Object.keys(changedValue)[0]);
        }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Imię jest wymagane' }]}
          validateStatus={
            usernameError !== '' ? 'error' : loading ? 'validating' : 'success'
          }
          help={usernameError}
        >
          <FormInput placeholder={'Imię'} />
        </Form.Item>
        {/* do we need SURNAME here? */}
        <Form.Item
          name="email"
          rules={[
            { type: 'email', message: 'Wprowadź prawidłowy adres email' },
            { required: true, message: 'Email jest wymagany' },
          ]}
          validateStatus={
            emailError !== '' ? 'error' : loading ? 'validating' : 'success'
          }
          help={emailError}
        >
          <FormInput placeholder={'Email'} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Hasło jest wymagane' },
            { min: 8, message: 'Hasło musi zawierać przynajmniej 8 znaków' },
          ]}
          validateStatus={
            passwordError !== '' ? 'error' : loading ? 'validating' : 'success'
          }
          help={passwordError}
        >
          <Input.Password className="formInput" placeholder={'Hasło'} />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Potwierdź swoje hasło',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Wpisane hasła do siebie nie pasują')
                );
              },
            }),
          ]}
          validateStatus={
            confirmError !== '' ? 'error' : loading ? 'validating' : 'success'
          }
          help={confirmError}
        >
          <Input.Password className="formInput" placeholder={'Powtórz hasło'} />
        </Form.Item>
        <Form.Item
          name="consent"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
          className="sing-up-form-checkbox"
          rules={[
            {
              required: true,
              transform: value => value || undefined,
              type: 'boolean',
              message: 'Zgody formalne są wymagene',
            },
          ]}
          help={consentError}
        >
          <Checkbox>
            Oświadczam, że zapoznałam/łem się z
            {/* TODO terms and conditions link */}
            <Link to={ROUTES.TERMS_AND_CONDITIONS}>
              Regulaminem Platformy
            </Link>{' '}
            oraz
            <Link to={ROUTES.PRIVACY_POLICY}>
              Polityką przetwarzania danych osobowych.
            </Link>
          </Checkbox>
        </Form.Item>
        {error && (
          <Paragraph type="danger" className="errot-text">
            Błędne dane rejestracji
          </Paragraph>
        )}
        <Form.Item>
          <Button
            type="primary"
            className="btn-mobile sign-up"
            htmlType="submit"
          >
            Zarejestruj się
          </Button>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="sing-up-form-modal"
      >
        <Paragraph type="success" className="sing-up-form-modal-text">
          Zajrzyj na swojego maila w celu weryfikacji konta.
          <Text>&#127881;</Text>
        </Paragraph>
      </Modal>
    </>
  );
};

export default SignUpForm;
