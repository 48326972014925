import React from 'react';
import { Input } from 'antd';
import './../../styles/globalStyles.less';

type FormInputProps = {
  placeholder?: string;
};

export const FormInput: React.FC<FormInputProps> = ({ ...props }) => {
  return <Input className={'formInput'} {...props} />;
};
