import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { REGISTER_MUTATION } from '../../queries/registerMutation';

export type UseSignupProps = {
  username: String;
  email: String;
  password: String;
};

export const useSignup = () => {
  const [register, { loading, error }] = useMutation(REGISTER_MUTATION);
  const handleSignup = useCallback(
    ({ username, email, password }: UseSignupProps) =>
      register({
        variables: {
          input: {
            username: `${username}`,
            email: `${email}`,
            password: `${password}`,
          },
        },
        onCompleted: ({ register }) => {
          return register;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { handleSignup, loading, error };
};
